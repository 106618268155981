<template>
<div class="app-container">
  <div style="margin-bottom:10px;display: flex;justify-content: space-between;">
    <div>
      <el-input size="small" placeholder="公司名称" v-model="listQuery.name" style="width:150px" @keyup.enter="getList"></el-input>
      <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
        <el-icon><Search /></el-icon> <span  >搜索</span>
      </el-button>
      <search @success="getList">
        <el-form style="margin:21px 0px 0px 6px;height:150px;width:700px" class="styleForm">
          <el-form-item label="纳税人类型 ：" :label-width="formLabelWidth">
            <selecttaxtype v-model:type="listQuery.type"></selecttaxtype>
          </el-form-item>
          <el-form-item label="初始化状态 ：" :label-width="formLabelWidth"> 
            <selectCollectStatus v-model:collectStatus="listQuery.setUpStatusCj"></selectCollectStatus>
          </el-form-item>

          <el-form-item label="选择人员 ：" :label-width="formLabelWidth">
            <selectuser code="bookkeeping" v-model:userId="listQuery.userId"></selectuser>
          </el-form-item>

        </el-form>
      </search>
    </div>
    <div>
      <!-- 税务初始化 -->
      <qzf-video v-if="this.activeName == '1'" vid="4467be5374c622f7f39b7da116cf30ea_4"></qzf-video>
      <!-- c财务初始化 -->
      <qzf-video v-if="this.activeName == '3'" vid="4467be53747d7400d9c76fc5774601f0_4"></qzf-video>


      <el-button @click="handleCateg()" size="small" type="primary" v-if="this.activeName == '1'">
        <el-icon><HelpFilled /></el-icon> <span  > 更新税种</span>
      </el-button>
      <el-button @click="sbzq()" size="small" type="primary" v-if="this.activeName == '1'">
        <el-icon><HelpFilled /></el-icon> <span  >批量设置申报周期</span>
      </el-button> 
      <el-button @click="handleTaxTable()" size="small" type="warning" v-if="this.activeName == '1'">
        <el-icon><Aim /></el-icon> <span  > 重新初始化</span>
      </el-button> 


      <el-dropdown size="small" style="margin-left:10px;margin-right:10px" :hide-on-click="false" v-if="this.activeName == '1'">
      <el-button size="small" type="primary">
        更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
      </el-button>
      <template #dropdown>
        <el-dropdown-menu style="text-align:center">
          <el-dropdown-item command="a">
            <span @click="cancelTask('tax-cj-taxCategory')">撤销更新税种</span>
          </el-dropdown-item>
          <el-dropdown-item command="b">
            <span @click="cancelTask('tax-cj-resetTaxs')">撤销重新初始化</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>


    </div>
  </div>
  <el-table stripe :data="list" :height="contentStyleObj" style="width: 100%" v-loading="loading" border @selection-change="handleSelectionChange" >
    <template #empty>
      <el-empty :image-size="150" description="没有数据"></el-empty>
    </template>
    <el-table-column align="center" prop="id" type="selection" min-width="55" />
    <el-table-column align="center" fixed type="index" label="编号" width="55" />
    <el-table-column prop="name" fixed label="公司名称" min-width="280" >
      <template #default="scope">
        <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
        <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
        <span type="text">{{ scope.row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="startAccountPeriod" label="启用账期" min-width="60">
      <template #default="scope">
        <span>{{ scope.row.startAccountPeriod }}</span>  
      </template>
    </el-table-column>

    <el-table-column align="center" prop="ndks" label="上次初始化账期" min-width="80" v-if="this.activeName == '1'">
      <template #default="scope">
        <span>{{ scope.row.mccjPeriod }}</span>  
      </template>
    </el-table-column>

    <el-table-column prop="accountSystem" label="纳税人类型" width="170">
      <template #default="scope">
        <span>{{ scope.row.accountSystem }}</span>  
      </template>
    </el-table-column>
    <el-table-column  prop="accountSystem" label="初始化状态" min-width="130">
      <template #default="scope">
        <div class="item_icon">
          <i v-if="scope.row.init.tax_category_methon == '已采集'" class="iconfont icon-duihao"></i>
          <i v-if="scope.row.init.tax_category_methon == '跳过采集'" class="iconfont icon-info"></i>
          <i v-if="scope.row.init.tax_category_methon == '采集中' || scope.row.init.tax_category_methon == '排队中'" class="iconfont icon-info"></i>
          <i v-if="scope.row.init.tax_category_methon == '未采集'" class="iconfont icon-gantanhao"></i>
          <i v-if="scope.row.init.tax_category_methon == '采集失败'" class="iconfont icon-cuowu"></i>
        </div>
        <el-tooltip class="item" effect="dark" v-if="scope.row.FailReason" :content="scope.row.FailReason" placement="top-start">
          <el-button size="small" class="sbcg" style="border: 0;padding: 0;font-size: 14px;">{{scope.row.init.tax_category_methon}}</el-button>
        </el-tooltip>
        <span v-else>{{ scope.row.init.tax_category_methon }}</span>  
      </template>
    </el-table-column>
    <el-table-column  prop="accountSystem" label="匹配状态" min-width="130" class-name="small_cell">
      <template #default="scope">
        <!-- 匹配 -->
        <el-tooltip placement="top" :disabled="scope.row.PpFailReason == ''">
          <template #content> 
            <div v-html="Tobreak(scope.row.PpFailReason)"></div>
          </template>
          <div class="item_icon" v-if="scope.row.setUpStatusCj == 3 || scope.row.setUpStatusCj == 9">
            <i class="iconfont icon-info"></i>
            <span>{{setUpStatus1(scope.row.setUpStatusCj)}}</span>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 6">
            <i class="iconfont icon-gantanhao"></i>
            <span>{{ setUpStatus3(scope.row.setUpStatusPp) }}</span>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 1">
            <i class="iconfont icon-duihao"></i>
            <span>{{ setUpStatus3(scope.row.setUpStatusPp) }}</span>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 2">
            <i class="iconfont icon-duihao"></i>
            <span>{{ setUpStatus3(scope.row.setUpStatusPp) }}</span>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 3">
            <i class="iconfont icon-info"></i>
            <span>{{ setUpStatus3(scope.row.setUpStatusPp) }}</span>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 4">
            <i class="iconfont icon-cuowu"></i>
            <span style="color:red">{{ setUpStatus3(scope.row.setUpStatusPp) }}</span>
          </div>
        </el-tooltip>  
      </template>
    </el-table-column>
    <el-table-column label="税务报表初始化状态" header-align='center' v-if="this.activeName == '1'">
      <el-table-column prop="init" label="增值税" align="center">
        <template #default="scope">
          <div class="item_icon" v-if="scope.row.init.tax_zzs == 1">
            <i class="iconfont icon-duihao"></i>
          </div>
          <div class="item_icon" v-else>
            <i class="iconfont icon-cuowu"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="所得税" prop="init" align="center">
        <template #default="scope">
           <div class="item_icon" v-if="scope.row.init.tax_sds == 1">
            <i class="iconfont icon-duihao"></i>
          </div>
          <div class="item_icon" v-else>
            <i class="iconfont icon-cuowu"></i>
          </div>
        </template>
      </el-table-column>
    </el-table-column>
    <el-table-column align="center" prop="ndks" label="弥补亏损" min-width="80" v-if="this.activeName == '1'">
      <template #default="scope">
        <span @click="annualLoss(scope.row.id)" style="cursor:pointer;color:#39b0d2;text-decoration:underline">
          <i class="iconfont icon-bianji"></i>
          {{ scope.row.init.ndks }}</span>
      </template>
    </el-table-column>
    <el-table-column label="财报报表初始化状态" header-align='center' v-if="this.activeName == '1'">
      <el-table-column prop="endCount" label="资产负债表" align="center" min-width="100">
        <template #default="scope">
          <div class="item_icon" v-if="scope.row.init.kj_zcfz == 1">
            <i class="iconfont icon-duihao"></i>
          </div>
          <div class="item_icon" v-else>
            <i class="iconfont icon-cuowu"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="利润表" prop="init" align="center">
        <template #default="scope">
          <div class="item_icon" v-if="scope.row.init.kj_lrb == 1">
            <i class="iconfont icon-duihao"></i>
          </div>
          <div class="item_icon" v-else>
            <i class="iconfont icon-cuowu"></i>
          </div>
        </template>
      </el-table-column>
    </el-table-column>
    <el-table-column label="建账初始化状态" header-align='center' v-if="this.activeName == '3'">
      <el-table-column prop="endCount" label="余额表上传" align="center" min-width="120px">
        <template #default="scope">
          <div class="item_icon" v-if="scope.row.init.subject >= 2" style="width:100%">
            <i class="iconfont icon-duihao"></i>
            {{scope.row.init.subject_methon}}
          </div>
          <div class="item_icon" v-else>
            <i class="iconfont icon-cuowu"></i>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="init" label="建账来源" align="center">
        <template #default="scope">
          <span v-if="scope.row.init.subject >= 2">{{scope.row.init.subject_methon}}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="科目匹配" prop="init" align="center">
        <template #default="scope">
          <div class="item_icon" v-if="scope.row.init.subject >= 3">
            <i class="iconfont icon-duihao"></i>
          </div>
          <div class="item_icon" v-else>
            <i class="iconfont icon-cuowu"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="endAmount" label="数据确认" align="center">
        <template #default="scope">
          <div class="item_icon" v-if="scope.row.init.subject >= 4">
            <i class="iconfont icon-duihao"></i>
          </div>
          <div class="item_icon" v-else>
            <i class="iconfont icon-cuowu"></i>
          </div>
        </template>
      </el-table-column>
    </el-table-column>
    <el-table-column align="center" prop="address" label="操作" min-width="100" v-if="activeName == 1">
      <template #default="scope">
        <el-button @click="handleUpdate(scope.row)" size="small" type="text">查看</el-button>
        <!-- todo -->
      </template>
    </el-table-column>
  </el-table>
  <div class="pagination">
    <qzf-pagination v-show="userTotal>0" :total="userTotal" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
  </div>

  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogVisible" title="初始化报表" width="60%" >
    <el-table :data="list2" border height="400px" style="margin-bottom:10px">
      <el-table-column align="left" prop="tableName" label="申报列表名称" min-width="240">
        <template #default="scope">
          <span type="text">{{ $tableNameFilter(scope.row.tableName) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="period" label="税款所属期" min-width="150" >
        <template #default="scope">
          <span type="text">{{ scope.row.period }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="name" label="初始化状态" min-width="150" >
        <template #default="scope">
          <span type="text">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="name" label="操作" min-width="180" class-name="top_right_btns">
        <template #default="scope">
          <el-tooltip content="查看" placement="top">
            <el-icon @click="editTax(scope.row)"><View /></el-icon>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="dialogVisible = false">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 批量设置申报周期 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogVisibleSbzq" title="批量设置申报周期" width="60%" >
    <el-table :data="sbzqList" border height="400px" style="margin-bottom:10px">
      <el-table-column align="left" prop="tableName" label="税种名称" min-width="240">
        <template #default="scope">
          <span type="text">{{ scope.row.collectionProject }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="period" label="申报周期" min-width="150" >
        <template #default="scope">
          <el-select size="small" v-model="scope.row.types" multiple collapse-tags collapse-tags-tooltip
            style="width: 90%">
            <el-option v-for="item in type" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>

    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisibleSbzq = false">取消</el-button>
        <el-button size="small" type="primary" @click="sbzqSave">确定</el-button>
      </span>
    </template>
  </el-dialog>


  <!-- 批量重新初始化 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogVisibleCsh" title="批量设置申报周期" width="60%" >
    
    <el-form label-width="120px">
      <el-form-item label="方式">
        <el-radio-group v-model="cshStatus">
          <el-radio :label="1">默认初始账期</el-radio>
          <el-radio :label="2">自选账期</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="cshStatus == 2" label="账期">
        <qzf-search-period v-model:period="cshPeriod"></qzf-search-period>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisibleCsh = false">取消</el-button>
        <el-button size="small" type="primary" @click="csh">确定</el-button>
      </span>
    </template>
  </el-dialog>


</div>
  <annualLossCom ref="annualLossCom" @success="getList"></annualLossCom>
</template>

<script>
import { eaCompanyList } from "@/api/company"
import { getInitTax, taxCategorySbzqEdit } from "@/api/taxCalculation"
import annualLossCom from "./annualLossCom.vue"
import selectCollectStatus from "@/components/Screening/selectCollectStatus";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import { currentAccountPeriod } from '@/utils'
import { sendTask, quitTask } from "@/api/collection"
import loginVue from '../../login/login.vue'
export default {
  name:'initTable',
  props: {
    activeName: {
      type: String,
      default:''
    },
  },
  components:{
    annualLossCom,
    selectCollectStatus,
    selecttaxtype
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(290)
    this.initBus()
    console.log(this.activeName);
  },
  data() {
    return {
      dialogVisibleCsh: false,
      cshStatus: 1,
      cshPeriod: "",
      sbzqList:[{
        collectionProject: "残疾人就业保障金",
        type: "",
        types: [],
      },{
        collectionProject: "工会经费",
        type:"",
        types: [],
      }],
      type: [
        {
          value: '月',
          label: '月报'
        }, {
          value: '季',
          label: '季报'
        }, {
          value: '次',
          label: '次报'
        }, {
          value: '不申报',
          label: '不申报'
        }, {
          value: '01',
          label: '一月'
        }, {
          value: '02',
          label: '二月'
        }, {
          value: '03',
          label: '三月'
        }, {
          value: '04',
          label: '四月'
        }, {
          value: '05',
          label: '五月'
        }, {
          value: '06',
          label: '六月'
        }, {
          value: '07',
          label: '七月'
        }, {
          value: '08',
          label: '八月'
        }, {
          value: '09',
          label: '九月'
        }, {
          value: '10',
          label: '十月'
        }, {
          value: '11',
          label: '十一月'
        }, {
          value: '12',
          label: '十二月'
        }
      ],
      list:[],
      list2:[],
      userTotal:0,
      dialogVisible:false,
      dialogVisibleSbzq: false,
      accountSystem:'',
      startAccountPeriod:'',
      comId:0,
      qysdsType:0,
      listQuery:{
        page:1,
        limit:20,
        from:'init',
        name:''
      },
      loading:false,
      formLabelWidth:'100px',
      gsMainIds: undefined,
      pageStatus: true,

      initTaxNo:'',
      initName:''
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  methods:{
    sbzqSave(){
      this.sbzqList.map(v=>{
        v.type = v.types.join(",")
      })
      let parma  = {
        comIds: this.gsMainIds,
        categorys:  this.sbzqList,
      }
      taxCategorySbzqEdit(parma).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("操作成功")
        }
      })
    },
    sbzq(){
      this.sbzqList = [{
        collectionProject: "残疾人就业保障金",
        type: "",
        types: [],
      },{
        collectionProject: "工会经费",
        type:"",
        types: [],
      }]
      this.dialogVisibleSbzq = true
    },
    cancelTask(taskName){
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$confirm('确定撤销采集任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dialogVisibleSbzq = false
        quitTask({taskName:taskName, comIds: this.gsMainIds}).then(res => {
          if(res.data.msg == 'success') {
            this.$cancelMsg(res.data.data.list)
            this.getList()
          }
        })
      })
    },
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds && this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if(res.init.tax_category_methon == '采集中') {
            status = true
          }
        })
        if(status == true) {
          this.getList()
        }
      });
    },
    getList(){
      this.loading = true
      eaCompanyList(this.listQuery).then(res=>{
        this.loading = false
        this.list = res.data.data.list
        this.userTotal = res.data.data.total
      })
    },
    handleUpdate(row){
      this.initTaxNo = row.taxNo
      this.initName = row.name
      this.accountSystem = row.accountSystem
      this.comId = row.id
      this.qysdsType = row.qysdsStatus
      getInitTax({comId:row.id}).then(res=>{
        this.list2 = res.data.data.list 
        this.dialogVisible = true
      })
    },
    handleSelectionChange(row) {
      this.gsMainIds = []
      row.map(info=>{
        this.gsMainIds.push(info.id)
      })
    },
    //更新税种
    handleCateg() {
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let param = {
        taskName: 'tax-cj-taxCategory',
        comIds: this.gsMainIds
      }
      sendTask(param).then(res => {
        if(res.data.msg == 'success') {
          this.$qzfMessage('采集任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)

        }
      })
    },
    csh(){
      let param = {
        taskName: 'tax-cj-resetTaxs',
        comIds: this.gsMainIds,
      }
      if(this.cshStatus == 2 && this.cshPeriod != ""){
        param.period = this.cshPeriod
      }
      this.dialogVisibleCsh = false
      sendTask(param).then(res => {
        if(res.data.msg == 'success') {
         this.$qzfMessage('采集任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    //重新采集税表
    handleTaxTable() {
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.dialogVisibleCsh = true
    },
    editTax(row){
      console.log(row);
      this.startAccountPeriod = row.period
      this.$store.dispatch("commons/setParam", {type: this.accountSystem})
      this.$store.dispatch("commons/setParam", {startAccountPeriod: this.startAccountPeriod})
      this.$store.dispatch("commons/setParam", {comId: this.comId})
      this.$store.dispatch("commons/setParam", {qysdsType: this.qysdsType})
      this.$store.dispatch("commons/setParam", {tableName: row.tableName})
      this.$store.dispatch("commons/setParam", {initTaxNo: this.initTaxNo})
      this.$store.dispatch("commons/setParam", {initName: this.initName})

      if(row.tableName == 'kj_lrb'|| row.tableName == 'kj_zcfz'|| row.tableName == 'kj_xjllb'){
          this.$router.push({
          path: "/initEa/kjInitTable",   //会计报表  ok
        });
      }else if(row.tableName == 'gs_vat'){
          this.$router.push({
          path: "/initEa/initVatInvoice",   //增值税报表 一般纳税人  ok
        });
      }else if(row.tableName == 'gs_small_vat'){
          this.$router.push({
          path: "/initEa/initSmallVat",   //增值税报表 小规模  ok
        });
      }else if(row.tableName == 'gs_tax_quarter'){
          this.$router.push({
          path: "/initEa/initQysds",   //企业所得税
        });
      }else if(row.tableName == 'gs_deed'){
          this.$router.push({
          path: "/initEa/initPropertyTax",   //财产和行为纳税申报表
        });
      }else if(row.tableName == 'gs_qtsr'){
          this.$router.push({
          path: "/initEa/initTaxNeimengQtsr",   //其他收入(工会经费)
        });
      }else if(row.tableName == 'gs_sl'){
          this.$router.push({
          path: "/initEa/initTaxNeimengSl",   //水利建设专项收入
        });
      }else if(row.tableName == 'gs_ljcl'){
          this.$router.push({
          path: "/initEa/initTaxCityLife",   //城市生活垃圾处置费
        });
      }else if(row.tableName == 'gs_xfs'){
          this.$router.push({
          path: "/initEa/initXfs",   //消费费
        });
      }else if(row.tableName == 'gs_cbj'){
          this.$router.push({
          path: "/initEa/initCbj",   //残疾人就业保障金申报表
        });
      }else if(row.tableName == 'gs_whsyjsfsbbygz'){
          this.$router.push({
          path: "/initEa/initCulturalTax",   //文化事业建设费申报表
        });
      }
    },
    annualLoss(id){
      this.$refs.annualLossCom.getListKS(id)
    },
    Tobreak(val){
      return val.replace(/[\r\n]/g, '<br/>')
    },
    setUpStatus1(e) {
      let num = e;
      if (num == 1) {
        return "已采集";
      } else if (num == 6) {
        return "未采集";
      } else if (num == 2) {
        return "跳过采集";
      } else if (num == 3) {
        return "排队中";
      } else if (num == 4) {
        return "采集失败";
      } else if (num == 5) {
        return "部分采集失败";
      } else if (num == 9) {
        return "采集中";
      }
    },
    setUpStatus2(e) {
      let num = e;
      if (num == 1) {
        return "已初始化";
      } else if (num == 6) {
        return "未初始化";
      }else if (num == 2) {
        return "无需初始化";
      }
    },
    setUpStatus3(e) {
      let num = e;
      if (num == 1) {
        return "初始化成功";
      } else if (num == 6) {
        return "未初始化";
      } else if (num == 2) {
        return "部分初始化失败";
      } else if (num == 3) {
        return "初始化中";
      } else if (num == 4) {
        return "初始化失败";
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.top_right_btns {
  i{
    margin: 0 5px;
    cursor: pointer;
    color: $color-main;
    font-size: 18px;
    line-height: 24px;
  }
}
.item_icon{
  display: inline-block;
  // width: 32%;
  // padding: 1px 3px;
  // padding-right: 10px;
  
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }
}
.el-select{
  margin-left: 0px;
}
.small_cell {
  div{
    padding-left: 2px !important;
    padding-right: 0 !important;
  }
}
</style>