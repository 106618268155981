<template>
  <div style="margin: 15px">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="累计数据" name="first">
        <batchTotal ref="first" :listQuery="this.listQuery"></batchTotal>
      </el-tab-pane>

      <el-tab-pane label="专项附加扣除" name="second">
        <batchTotal ref="second" :listQuery="this.listQuery"></batchTotal>
      </el-tab-pane>
    <div class="topBtn">
      <div>
        <!-- <el-date-picker @change="getList" v-model="listQuery.period" type="month" value-format="YYYYMM" size="small" placeholder="日期" style="width: 160px;margin-right:5px;"></el-date-picker> -->
        <el-input size="small" placeholder="公司名称" v-model="listQuery.name" style="width:150px;margin-left:10px" @keyup.enter="getList"></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
          <el-icon><Search /></el-icon> <span  >搜索</span>
        </el-button>
        <search @success="getList">
          <el-form style="margin:21px 0px 0px 6px;height:150px;width:700px" class="styleForm">

          <el-form-item label="初始化状态 ：" :label-width="formLabelWidth">
            <el-checkbox-group size="small" v-model="listQuery.taskStatus">
              <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="选择人员 ：" :label-width="formLabelWidth">
            <selectuser code="bookkeeping" v-model:userId="listQuery.userId"></selectuser>
          </el-form-item>

          <el-form-item label="采集账期 ：" :label-width="formLabelWidth">
            <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
          </el-form-item>
        </el-form>
        </search>
      </div>
      <div>
        <qzf-video vid="4467be53748bba4a62a6c720a8079dba_4"></qzf-video>
        
        <el-button style="margin-left:10px" v-if="listQuery.taskName == 'gs-cj-ljsjcj'" type="primary" size="small" @click="handleljcsh()" >
          <el-icon><Files /></el-icon><span  > 累计初始化</span>
        </el-button>
        <el-button style="margin-left:10px" v-if="listQuery.taskName == 'gs-cj-zxfjkccj'" type="primary" size="small" @click="handleZxkccsh()">
          <el-icon><DocumentRemove /></el-icon><span  > 专项扣除初始化</span>
        </el-button>
        <!-- <qzf-button jz="false" button_code="yg_ss" class="filter-item">累计初始化</qzf-button> -->
        <!-- <qzf-button jz="false" button_code="yg_ss" class="filter-item" style="margin-left:10px;" v-if="listQuery.taskName == 'per-cj-zxfjkc'" type="primary" size="small" @success="handlezxkc()" plain>专项扣除初始化</qzf-button> -->
        <!-- <el-dropdown @command="batchAll" style="margin-left:6px" size="small">
          <qzf-button jz="false" button_code="xc_yg_gd" type="primary" size="small">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </qzf-button>
          <template #dropdown>
            <el-dropdown-menu style="text-align:center">
              <el-dropdown-item command="a">
                <span>一键配置法人</span>
              </el-dropdown-item>
              <el-dropdown-item command="b">
                <span>采集人员信息</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
          
        </el-dropdown> -->
      </div>
    </div>
    <el-table stripe :height="contentStyleObj" @selection-change="handleSelectionChange" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%; margin-top: 10px;" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" align="center" width="40"/>
      <el-table-column align="center" type="index" width="50" label="序号"> </el-table-column>
      <el-table-column label="初始账期" align="center" min-width="100">
        <template #default="{row}">
              <span>{{row.startAccountPeriod}}</span>
        </template>
      </el-table-column>
      <el-table-column label="公司名称" align="left" min-width="240">
          <template #default="{row}">
            <span v-if="row.type == '1'" class="xgmStyle">小</span>
            <span v-if="row.type == '2'" class="ybStyle">般</span>
            <!-- <el-button type="text">{{ row.name }}</el-button> -->
            <span>{{row.name}}</span>
          </template>
      </el-table-column>
      <el-table-column label="状态" min-width="100">
        <template #default="scope">
          <span v-if="scope.row.image" >
            <i class="iconfont icon-picture" v-if="scope.row.taskStatus == 3" style="color:#67c23a;font-size:16px;" @click="open(scope.row, scope.$index)"></i>
            <i class="iconfont icon-picture" v-if="scope.row.taskStatus == 4" style="color:red;font-size:16px;" @click="open(scope.row, scope.$index)"></i>

          </span>
          <div class="item_icon">
            <i v-if="scope.row.taskStatus == 0 && !scope.row.image" class="iconfont icon-gantanhao"></i>
            <i v-if="scope.row.taskStatus == 1 && !scope.row.image" class="iconfont icon-info"></i>
            <i v-if="scope.row.taskStatus == 2 && !scope.row.image" class="iconfont icon-info"></i>
            <i v-if="scope.row.taskStatus == 3 && !scope.row.image" class="iconfont icon-duihao"></i>
            <i v-if="scope.row.taskStatus == 4 && !scope.row.image" class="iconfont icon-cuowu"></i>
          </div>
          <span>
            <span v-if="scope.row.taskStatus == 0" class="wsb">未采集</span>
            <span v-if="scope.row.taskStatus == 1" class="sbz">排队中</span>
            <span v-if="scope.row.taskStatus == 2" class="sbz">采集中</span>
            <span v-if="scope.row.taskStatus == 3" class="sbcg2">采集完成</span>
            <span v-if="scope.row.taskStatus == 4" class="sbyc2">采集失败</span>
            
          </span>  
        </template>
      </el-table-column>
      <el-table-column label="初始时间" align="center" min-width="160">
        <template #default="{row}">
          <span>{{row.beginAt}}</span>
        </template>
      </el-table-column>
      <el-table-column label="专项扣除初始化" align="center" min-width="170" v-if="listQuery.taskName == 'gs-cj-zxfjkccj'">
        <template #default="{row}">
          <span>{{row.beginAt}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="160">
        <template #default="scope">
          <el-button @click="checkDetail(scope.row)" size="small" type="text">查看</el-button>
          <el-button @click="handleTaxTable(scope.row)" size="small" type="text">重新初始化</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="userTotal>0" :total="userTotal" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
    </el-tabs>

    <!-- 批量重新初始化 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogVisibleCsh" title="批量设置申报周期" width="60%" >
    
    <el-form label-width="120px">
      <el-form-item label="方式">
        <el-radio-group v-model="cshStatus">
          <el-radio :label="1">默认初始账期</el-radio>
          <el-radio :label="2">自选账期</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="cshStatus == 2" label="账期">
        <qzf-search-period v-model:period="cshPeriod"></qzf-search-period>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisibleCsh = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleljcsh">确定</el-button>
      </span>
    </template>
  </el-dialog>
  </div>
  <sbImgUrl ref="sbImgUrl"></sbImgUrl>

</template>

<script>
import { companyInitTaxListNoPeriod } from '@/api/company.js'
import { sendTask } from "@/api/collection"
import sbImgUrl from '../../batch/components/sbImgUrl.vue'
export default {
  name:'personalTax',
  components:{
    sbImgUrl
  },
  data() {
    return {
      activeName: "first",
      listQuery:{
        page:1,
        limit:20,
        taskName: 'gs-cj-ljsjcj',
        name:''
      },
      userTotal:0,

      list: [],
      gsMainIds: undefined,
      pageStatus: true,
      listLoading:false,
      options:[
        {
          value: 0,
          label: '未采集',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '采集中',
        },
        {
          value: 3,
          label: '采集完成',
        },
        {
          value: 4,
          label: '采集失败',
        }
      ],
      dialogVisibleCsh: false,
      cshStatus: 1,
      cshPeriod: "",
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created() {
    this.contentStyleObj= this.$getHeight(280)
    this.getList()
    this.initBus()
  },
  methods: {
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds && this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if(res.taskStatus == 1) {
            status = true
          }
        })
        if(status == true) {
          this.getList()
        }
      });
    },
    getList() {
      this.listLoading = true
      companyInitTaxListNoPeriod(this.listQuery).then(res => {
        this.listLoading = false
        this.list = res.data.data.list ? res.data.data.list : []
        this.userTotal = res.data.data.total

      })
    },
    handleClick(tab) {
      if(tab.props.name =='first'){
        this.listQuery.taskName = 'gs-cj-ljsjcj'
        this.getList()
      }else if (tab.props.name == "second"){
        this.listQuery.taskName = 'gs-cj-zxfjkccj'
        this.getList()
      }
    },
    checkDetail(row){
      this.$store.dispatch('commons/setParam', {informationPeriod: row.startAccountPeriod,informationComId: row.comId})
      // this.$store.dispatch('tagsView/delCachedViewByName', "information")
      this.$router.push({
        path: "/initEa/initChushihua",
        // name: "information"
        name: "initChushihua"
      });
    },
    handleSelectionChange(row) {
      this.gsMainIds = []
      row.map(info=>{
        this.gsMainIds.push(info.comId)
      })
    },
    //重新采集税表
    handleTaxTable(row) {
      let param = {
        taskName: this.listQuery.taskName,
        period: this.listQuery.period,
        comIds: [row.comId],
      }
      sendTask(param).then(res => {
        if(res.data.msg == 'success') {
          this.$qzfMessage('已重新采集税表',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)

        }
      })
    },
    handleZxkccsh() {
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.dialogVisibleCsh = true
    },
    //累计初始化
    handleljcsh() {
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let param = {
        taskName: this.listQuery.taskName,
        comIds: this.gsMainIds,
      }
      if(this.cshStatus == 2 && this.cshPeriod != ""){
        param.period = this.cshPeriod
      }
      this.dialogVisibleCsh = false
      sendTask(param).then(res => {
        if(res.data.msg == 'success') {
          this.$qzfMessage('已累计初始化',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    open(row, index) {
      let imageAll = []
      this.list.map(res => {
        let url = {
          name: res.name,
          url: ""
        }
        url.url = res.image ? 'https://file.listensoft.net' + res.image + "?" + Math.random(10): ""
        imageAll.push(url)
      })
      this.$refs.sbImgUrl.dialog(imageAll, index)
    },
  }
}
</script>

<style lang="scss" scoped>
.topBtn {
  display: flex;
  margin-bottom:10px;
  justify-content: space-between;
}
.item_icon{
  display: inline-block;
  // width: 12%;
  // padding: 1px 3px;
  // padding-right: 10px;
  
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }
}
 
</style>