<template>
	<el-checkbox-group size="small" @change="changeValue" v-model="collectStatus">
		<el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
	</el-checkbox-group>
</template>

<script>
export default {
    name: 'selectCollectStatus',
    props: {
			collectStatus: {
				type: Array,
				default: []
			}
    },
    data() {
			return {
				options: [
					{
						value: 1,
						label: '已采集',
					},
					{
						value: 6,
						label: '未采集',
					},
					{
						value: 2,
						label: '跳过采集',
					},
					{
						value: 3,
						label: '采集中',
					},
					{
						value: 4,
						label: '采集失败',
					},
					{
						value: 5,
						label: '部分采集失败',
					},
				],
				value: '',
			}
    },
    methods:{
			changeValue() {
				this.$emit("update:collectStatus", this.collectStatus)
				this.$emit("success", this.collectStatus)
			},
    }
}
</script>

<style>

</style>